export default {
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
        type: null
      },
      visible: false,
      dateVisible: false
    };
  },
  methods: {
    handleFilterFormChange(form) {
      this.dateRange = { ...this.dateRange, startDate: form.payFinishTimeStart, endDate: form.payFinishTimeEnd };
      this.close();
      this.closeDatePopup();

      this.params = {
        ...form,
        payFinishTimeStart: this.dateRange.startDate,
        payFinishTimeEnd: this.dateRange.endDate
      };
      this.queryStatementOfAccountService();
    },

    handleDateChange(dateRange) {
      this.dateRange = { ...this.dateRange, ...dateRange };
      this.closeDatePopup();
    },

    openDatePopup() {
      this.dateVisible = true;
    },

    closeDatePopup() {
      this.dateVisible = false;
    },

    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    }
  }
};
