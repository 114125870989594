<template>
  <div class="filter-form bg-white">
    <div class="p-20">
      <div class="filter-item mb-16">
        <div class="text-14">车牌号码</div>
        <div class="bg-f8">
          <van-field v-model="formData.carLicensePlate" :border="false" clearable />
        </div>
      </div>
      <div class="filter-item mb-16">
        <div class="text-14">司机姓名</div>
        <div class="bg-f8">
          <van-field v-model="formData.driverName" :border="false" clearable />
        </div>
      </div>
      <div class="filter-item mb-16">
        <div class="text-14">运单号</div>
        <div class="bg-f8">
          <van-field v-model="formData.transportBillNumber" :border="false" clearable />
        </div>
      </div>
      <div class="filter-item mb-16 re">
        <div class="text-14">收款时间</div>
        <div class="bg-f8 h-40 flex justify-between items-center pl-10 pr-5 relative" @click="onSelectDate">
          <span>{{ dateRangeText }}</span>
          <van-icon
            @click.stop="onClear"
            v-if="dateRangeText"
            name="clear"
            class="van-field__clear content-clear-icon"
          />
        </div>
      </div>
    </div>
    <div class="flex text-16">
      <div class="text-secondary flex-1 h-48 flex justify-center items-center cancel-btn" @click="onRest">重置</div>
      <div class="text-white flex-1 h-48 flex justify-center items-center bg-primary" @click="onQuery">查询</div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

function initForm() {
  return {
    carLicensePlate: null,
    transportBillNumber: null,
    driverName: null,
    payFinishTimeStart: null,
    payFinishTimeEnd: null
  };
}

export default {
  props: {
    dateRange: {
      type: Object,
      default: () => ({
        startDate: null,
        endDate: null,
        type: null
      })
    }
  },
  data() {
    return {
      formData: initForm()
    };
  },
  methods: {
    onClear() {
      this.formData.payFinishTimeEnd = this.formData.payFinishTimeStart = null;
    },
    onSelectDate() {
      this.$emit('selectDate');
    },
    onQuery() {
      this.$emit('change', this.formData);
    },
    onRest() {
      this.formData = initForm();
      this.$emit('change', this.formData);
    }
  },
  computed: {
    dateRangeText() {
      const { payFinishTimeStart, payFinishTimeEnd } = this.formData;
      if (!payFinishTimeStart || !payFinishTimeEnd) return '';
      const startDate = dayjs(payFinishTimeStart);
      const endDate = dayjs(payFinishTimeEnd);

      // 按日选择 只显示月份
      if (this.dateRange.type === 2) {
        return startDate.format('YYYY-MM');
      }

      // 判断是不是当天
      // if (startDate.startOf('day').valueOf() === endDate.startOf('day').valueOf()) {
      //   return startDate.format('YYYY-MM-DD')
      // }
      return startDate.format('YYYY-MM-DD') + ' - ' + endDate.format('YYYY-MM-DD');
    }
  },
  watch: {
    dateRange(obj) {
      const { startDate, endDate } = obj;
      if (startDate) {
        this.formData.payFinishTimeStart = startDate;
      }
      if (endDate) {
        this.formData.payFinishTimeEnd = endDate;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.filter-form {
  /deep/ .van-cell {
    padding: 0.1rem 0.12rem !important;
    background: #f8f8f8;
    border-radius: 0.04rem;
  }
}
.content-clear-icon {
  position: absolute;
  right: 0.12rem;
}
.bg-f8 {
  background: #f8f8f8;
  border-radius: 0.04rem;
  overflow: hidden;
}
.pr-10 {
  padding-right: 0.1rem;
}
.cancel-btn {
  background: rgba(0, 118, 255, 0.05);
}
</style>
