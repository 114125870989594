<template>
  <div class="statement-page flex flex-col">
    <!-- 导航栏 -->
    <NavBar title="对账单" @clickRight="handleNavBarRight">
      <!-- 自定义右边样式 -->
      <template #right>
        <div class="flex items-center filter-wrapper" :class="{ active: isFilterStatus }">
          <span class="mr-6">筛选</span>
          <div class="triangle"></div>
        </div>
      </template>
    </NavBar>

    <!-- 列表有数据 -->
    <div class="flex-1 overflow-y-auto my-12" id="container" ref="container">
      <PullRefresh class="" style="height:100%;overflow:initial;" v-model="status.isPullDown" @refresh="onRefresh">
        <!-- 列表 -->
        <template v-for="(item, index) in list">
          <MonthCard
            :item="item"
            :key="item.payFinishTimeStart"
            :isFilterStatus="isFilterStatus"
            @click="handleClickItem(item, index)"
            @loadmore="handleLoadmore(item, $event)"
          />
        </template>

        <div class="text-12 text-muted text-center" v-if="!isFilterStatus && status.isInit && !status.isEmpty">
          仅展示近3个月的收入明细，更多记录请通过时间筛选查询
        </div>

        <template v-if="status.isInit && !list.length">
          <div class="flex flex-col justify-center items-center mt-60">
            <img
              src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_chazhaowujieguo%403x.png"
              alt=""
              style="width: 1rem;"
            />
            <span class="text-14" style="color: #999;">未查到相关明细</span>
          </div>
        </template>
      </PullRefresh>
    </div>

    <!-- 缺省页 -->
    <van-popup position="top" v-model="visible">
      <FilterForm :dateRange="dateRange" @selectDate="openDatePopup" @change="handleFilterFormChange" />
    </van-popup>
    <van-popup position="bottom" v-model="dateVisible">
      <FilterDateForm @change="handleDateChange" @cancel="closeDatePopup" />
    </van-popup>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/index';
import { PullRefresh, Toast } from 'vant';
import { queryPayeeIncomeStatsAPi, queryPayeeIncomeDetailAPi } from '@/api/driver';
import MonthCard from './components/MonthCard.vue';
import FilterForm from './components/FilterForm.vue';
import FilterDateForm from './components/FilterDateForm.vue';
import { isEqual, cloneDeep } from 'lodash-es';
import ModalMixins from './mixins';

function initParams() {
  return {
    carLicensePlate: null,
    transportBillNumber: null,
    driverName: null,
    payFinishTimeStart: null,
    payFinishTimeEnd: null
  };
}

export default {
  name: 'statement',
  components: { NavBar, PullRefresh, MonthCard, FilterForm, FilterDateForm },
  mixins: [ModalMixins],
  data() {
    return {
      list: [],
      status: {
        isPullDown: false,
        isEmpty: false,
        isInit: false
      },
      params: initParams()
    };
  },
  mounted() {
    this.queryStatementOfAccountService();
  },
  methods: {
    async queryStatementOfAccountService() {
      Toast.loading({ message: '加载中...', forbidClick: true });
      try {
        const res = await queryPayeeIncomeStatsAPi(this.params);

        if (res.data?.length) {
          const { payFinishTimeStart, payFinishTimeEnd } = res.data[0];
          const records = await this.queryPayeeIncomeDetailService({
            payFinishTimeStart,
            payFinishTimeEnd
          });
          res.data[0].visible = true;
          res.data[0].records = records || [];
          this.list = res.data;
          this.status.isEmpty = false;
        } else {
          this.list = [];
          this.status.isEmpty = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        Toast.clear();
        this.status.isInit = true;

        this.$refs.container.scrollTop = 0;
      }
    },

    async queryPayeeIncomeDetailService({ payFinishTimeStart, payFinishTimeEnd }) {
      const { data } = await queryPayeeIncomeDetailAPi({
        ...this.params,
        payFinishTimeStart,
        payFinishTimeEnd,
        pageNum: 1,
        pageSize: 100
      });
      return data.records;
    },

    async handleLoadmore(item, pageNum) {
      const { payFinishTimeStart, payFinishTimeEnd } = item;
      Toast.loading({ message: '加载中...', forbidClick: true });
      try {
        const { data } = await queryPayeeIncomeDetailAPi({
          ...this.params,
          payFinishTimeStart,
          payFinishTimeEnd,
          pageNum,
          pageSize: 100
        });
        item.records = [...item.records, ...(data.records || [])];
      } finally {
        Toast.clear();
      }
    },

    handleNavBarRight() {
      this.visible = true;
    },

    handleFormChange(form) {
      this.params = form;
      this.queryStatementOfAccountService();
    },

    // 下拉
    async onRefresh() {
      await this.queryStatementOfAccountService();
      this.status.isPullDown = false;
    },

    // 点击item 请求数据展开
    async handleClickItem(item, index) {
      item.visible = !item.visible;
      if (item.records) return;
      Toast.loading({ message: '加载中...', forbidClick: true });
      try {
        const { payFinishTimeStart, payFinishTimeEnd } = item;
        const records = await this.queryPayeeIncomeDetailService({ payFinishTimeStart, payFinishTimeEnd });
        item.records = records;
        this.list = cloneDeep(this.list);
      } catch (error) {
        console.log(error);
      } finally {
        Toast.clear();
      }
    }
  },
  computed: {
    isFilterStatus() {
      if (isEqual(this.params, initParams())) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="less" scoped>
.statement-page {
  height: 100vh;
  background: #f5f5f5;
}
.triangle {
  top: -0.02rem;
  width: 0;
  height: 0;
  border-left: 0.05rem solid transparent;
  border-right: 0.05rem solid transparent;
  border-top: 0.05rem solid #333;
}
.filter-wrapper.active {
  color: #0076ff;
  .triangle {
    border-top-color: #0076ff;
  }
}
</style>
