<template>
  <div class="date-picker-wrapper bg-white">
    <div class="btn-group flex justify-between items-center px-28 py-12 border-b-f7">
      <span class="text-16 text-secondary" @click="onCancel">取消</span>
      <span class="text-16 text-primary" @click="onConfirm">确定</span>
    </div>

    <div class="switch flex justify-center items-center" @click="onSwitchType">
      <span class="text-16 text-secondary mr-6">{{ typeTipsText }}</span>
      <img class="toggle-icon" :src="require('@/assets/misc/toggle.png')" mode="" />
    </div>

    <div class="px-12 mb-16">
      <div class="flex justify-center items-center" v-if="type === 1">
        <span
          class="flex-1 text-center py-12 border-b-c6"
          :class="{ active: current === 0 }"
          @click="onSwitchCurrent(0)"
        >
          {{ startDateText }}
        </span>
        <span class="mx-12 text-16" v-if="type === 1">至</span>
        <span
          class="flex-1 text-center py-12 border-b-c6"
          :class="{ active: current === 1 }"
          v-if="type === 1"
          @click="onSwitchCurrent(1)"
        >
          {{ endDateText }}
        </span>
      </div>
      <div v-if="type === 2">
        <span class="flex flex-1 justify-center items-center py-12 border-b-c6"> {{ curDateText }} </span>
      </div>
    </div>

    <template v-if="type === 1">
      <van-swipe ref="swipe" class="swiper" :show-indicators="false" @change="onSwitchCurrent" :duration="350">
        <van-swipe-item>
          <van-datetime-picker
            type="date"
            :formatter="formatter"
            :value="startDate"
            :min-date="startMinDate"
            :max-date="startMaxDate"
            :show-toolbar="false"
            :visible-item-count="3"
            @input="onStartDateChange"
          />
        </van-swipe-item>
        <van-swipe-item>
          <van-datetime-picker
            type="date"
            :formatter="formatter"
            :value="endDate"
            :min-date="endMinDate"
            :max-date="endMaxDate"
            :show-toolbar="false"
            :visible-item-count="3"
            @input="onEndDateChange"
          />
        </van-swipe-item>
      </van-swipe>
    </template>

    <template v-if="type === 2">
      <van-datetime-picker
        type="year-month"
        :formatter="formatter"
        :value="curDate"
        :min-date="startMinDate"
        :max-date="startMaxDate"
        :show-toolbar="false"
        :visible-item-count="3"
        @input="onDateChange"
      />
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { Toast } from 'vant';
export default {
  data() {
    return {
      type: 1, // 1 按月选择 2 按日选择

      current: 0, // 按月选择切换开始和结束时间

      startDate: dayjs()
        .subtract(179, 'day')
        .toDate(),
      startMinDate: dayjs()
        .subtract(179, 'day')
        .toDate(),
      startMaxDate: dayjs().toDate(),

      endDate: dayjs().toDate(),
      endMinDate: dayjs()
        .subtract(179, 'day')
        .toDate(),
      endMaxDate: dayjs().toDate(),

      curDate: dayjs().toDate()
    };
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onConfirm() {
      let startDate = null;
      let endDate = null;
      // 按月份选择
      if (this.type === 1) {
        if (
          dayjs(this.startDate)
            .startOf('date')
            .valueOf() >
          dayjs(this.endDate)
            .startOf('date')
            .valueOf()
        ) {
          return Toast.fail('开始日期不能大于结束日期');
        }
        startDate = dayjs(this.startDate)
          .startOf('date')
          .valueOf();
        endDate = dayjs(this.endDate)
          .endOf('date')
          .valueOf();
      }

      if (this.type === 2) {
        // 按月份选择
        startDate = dayjs(this.curDate)
          .startOf('month')
          .valueOf();
        endDate = dayjs(this.curDate)
          .endOf('month')
          .valueOf();
      }

      this.$emit('change', { startDate, endDate, type: this.type });
    },

    onStartDateChange(date) {
      this.startDate = date;
    },
    onEndDateChange(date) {
      this.endDate = date;
    },
    onDateChange(date) {
      this.curDate = date;
    },

    onSwitchType() {
      this.type = (this.type % 2) + 1;
    },

    onSwitchCurrent(index) {
      this.current = index;
      this.$refs.swipe.swipeTo(index, { immediate: false });
    },

    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`;
      }
      if (type === 'month') {
        return `${value}月`;
      }
      return `${value}日`;
    }
  },
  computed: {
    typeTipsText() {
      return this.type === 1 ? '按月选择' : '按日选择';
    },
    startDateText() {
      if (!this.startDate) return '请选择开始时间';
      return dayjs(this.startDate).format('YYYY-MM-DD');
    },
    endDateText() {
      if (!this.endDate) return '请选择结束时间';
      return dayjs(this.endDate).format('YYYY-MM-DD');
    },
    curDateText() {
      if (!this.curDate) return '请选择日期';
      return dayjs(this.curDate).format('YYYY-MM');
    }
  }
};
</script>

<style lang="less" scoped>
.border-b-f7 {
  border-bottom: 0.01rem solid #f7f7f7;
}
.border-b-c6 {
  border-bottom: 0.01rem solid #c6c6c6;

  &.active {
    color: #1677ff;
    border-bottom: 0.01rem solid #1677ff;
  }
}
.toggle-icon {
  width: 0.2rem;
}
.switch {
  width: 1.24rem;
  height: 0.3rem;
  margin: 0.2rem 0.28rem;
  background: #f8f8f8;
  border: 0.01rem solid #c6c6c6;
  border-radius: 0.28rem;
  image {
    width: 0.13rem;
    height: 0.15rem;
  }
}
</style>
