<template>
  <div class="month-card bg-white mb-16">
    <div class="stats px-20 pt-20 pb-16 relative" @click.stop="handleClickItem">
      <div class="flex items-baseline">
        <span class="font-600" :class="[isFilterStatus ? 'text-20' : 'text-28 ']">{{ month }} </span>
        <span class="text-12 ml-4" v-if="!isFilterStatus">月</span>
        <div class="triangle relative ml-4" :class="[item.visible ? 'up' : 'down']"></div>
      </div>

      <div class="flex items-center text-12 text-secondary">
        <span>累计收入：¥{{ item.incomeMoney }}</span>
        <span class="ml-20">收入笔数：{{ item.incomeCount }}</span>
      </div>

      <img :src="require('@/assets/misc/statement-icon.png')" class="bg-icon" />
    </div>
    <!-- {{ item.records }} -->
    <div class="list-wrapper" v-if="item.visible">
      <div class="list pl-38 pt-16 relative" v-for="(record, index) in item.records" :key="index">
        <div class="flex flex-col relative item pb-16">
          <div class="text-16 relative">
            <span>{{ formatPayFinishTime(record.payFinishTime) }}</span>
            <span class="ml-16 text-14 span-muted" v-if="record.payWayText">{{ record.payWayText }}</span>
            <div class="dot" :class="{ blue: isYesterday(record.payFinishTime) }"></div>
          </div>
          <span class="text-14 text-muted mt-14">司机信息：{{ record.driverName }} {{ record.driverPhone }}</span>
          <span class="text-14 text-muted mt-14">车辆号码：{{ record.carLicensePlate }}</span>
          <span class="text-14 text-muted mt-14">运单号：{{ record.transportBillNumber }}</span>
          <span class="text-14 text-muted mt-14">线路名称：{{ record.routeName }}</span>
          <span class="text-14 text-muted mt-14">装货时间：{{ record.loadingDateTime | parseTime }}</span>

          <div class="amount">
            <div class="font-500 text-20">+{{ record.incomeMoney }}</div>
            <span class="text-14 text-secondary">{{ record.payTypeName }}</span>
          </div>
        </div>
      </div>
      <div class="py-16 text-primary cursor-pointer more-btn text-center" v-if="showLoadMore" @click="onLoadMore">
        + 点击加载更多
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isFilterStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      lastLoadTime: 0
    };
  },
  methods: {
    handleClickItem() {
      this.$emit('click');
    },

    formatPayFinishTime(timestamp) {
      const dateTime = dayjs(timestamp);
      if (this.isYesterday(timestamp)) {
        return '昨天 ' + dateTime.format('HH:mm');
      }
      return dateTime.format('MM月DD日 HH:mm');
    },

    isYesterday(timestamp) {
      const dateTime = dayjs(timestamp)
        .startOf('date')
        .valueOf();
      const yesterday = dayjs()
        .subtract(1, 'day')
        .startOf('date')
        .valueOf();
      if (dateTime === yesterday) {
        return true;
      }
      return false;
    },
    getPageNo(total, size = 100) {
      if (total <= 0) return;
      const current = Math.ceil(total / size);
      return current;
    },
    onLoadMore() {
      const now = Date.now();
      if (now - this.lastLoadTime >= 5000) {
        this.lastLoadTime = now;
        this.$emit('loadmore', this.getPageNo(this.item.records?.length || 0) + 1);
      }
    }
  },
  computed: {
    month() {
      // 有可能有时间区间
      const { payFinishTimeStart, payFinishTimeEnd } = this.item;
      if (!this.isFilterStatus) {
        return dayjs(this.item.payFinishTimeStart).format('MM');
      }
      if (
        dayjs(payFinishTimeStart)
          .startOf('month')
          .valueOf() ===
        dayjs(payFinishTimeEnd)
          .startOf('month')
          .valueOf()
      ) {
        return dayjs(this.item.payFinishTimeStart).format('YYYY.MM');
      }
      return (
        dayjs(this.item.payFinishTimeStart).format('YYYY.MM.D') +
        ' - ' +
        dayjs(this.item.payFinishTimeEnd).format('YYYY.MM.D')
      );
    },
    showLoadMore() {
      const item = this.item;
      const records = item.records || [];
      const count = item.incomeCount;
      return this.getPageNo(records.length) < this.getPageNo(count);
    }
  },
  filters: {
    parseTime(timestamp) {
      if (!timestamp) return '--';
      return dayjs(timestamp).format('YYYY-MM-DD HH:mm');
    }
  }
};
</script>

<style lang="less" scoped>
.month-card {
  .stats {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: ' ';
      width: 100%;
      border-bottom: 1px solid #c6c6c6;
      transform: scaleY(0.5);
    }

    .bg-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0.9rem;
    }
  }

  .list-wrapper {
    .list:last-child .item {
      &::after {
        width: 0;
        border-bottom: 0;
      }
    }
  }

  .amount {
    position: absolute;
    right: 0.2rem;
    top: -0.03rem;
    text-align: right;
  }

  .item {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: ' ';
      width: 100%;
      border-bottom: 1px solid #c6c6c6;
      transform: scaleY(0.5);
    }

    .dot {
      position: absolute;
      width: 0.06rem;
      height: 0.06rem;
      border-radius: 0.06rem;
      left: -0.18rem;
      top: 0.08rem;
      background-color: #c6c6c6;
      &.blue {
        background-color: #0075ff;
      }
    }
  }

  .triangle {
    top: -0.02rem;
    width: 0;
    height: 0;
    &.up {
      border-left: 0.05rem solid transparent;
      border-right: 0.05rem solid transparent;
      border-bottom: 0.05rem solid #333;
    }
    &.down {
      border-left: 0.05rem solid transparent;
      border-right: 0.05rem solid transparent;
      border-top: 0.05rem solid #333;
    }
  }
}
</style>
